.vl_monitoring_panel {

    /* Layout Properties */

    position: absolute;
    top: 12.6vh;
    left: 21.5vw;
    width: 57vw;
    height: 82.5vh;
    min-height: 725px;
    z-index: 1;

    /* UI Properties */

    background-color: var(--common-grey);
    box-shadow: 0 3px 20px #00000029;
    border-radius: 30px;
    opacity: 1;
}

.vl_monitoring_header {

    margin-top: 3%;
    width: 100%;

    text-align: center;
    font: normal normal normal 61px/89px Adobe Clean;
    letter-spacing: 0;
    color: #414141;
}

.vl_monitoring_logs {

    margin-top: 2%;
    margin-left: 6%;
    width: 82%;
    height: 61%;
    overflow-y: scroll;
}

.vl_monitoring_select_all {

    display: flex;
    flex-direction: row;
    position: absolute;

    margin-left: 4%;
}

.vl_monitoring_select_all_text {

    margin-left: 25px;

    font: normal normal 300 22px/32px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}