.vl_add_map_button {

    position: relative;
    width: 147px;
    height: 40px;
    border: none;
    border-radius: 20px;
    background-color: #ffdc8d;
}

.vl_add_map_button:active {

    background-color: lightgray;
}

.vl_add_map_button_text {

    position: relative;
    left: -20px;
    font-size: 1.3rem;
}

.vl_add_map_button_icon_container {

    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background-color: #ffdc8d;
    border: 3px var(--common-grey) solid;
    right: 0;
    top: -2px;
}

.vl_add_map_button_icon {

    position: absolute;
    width: 32px;
    height: 32px;
    right: 2px;
    top: 8px;
    background: transparent url('/src/ui/images/Add.png') 0 0 no-repeat padding-box;
}