.vl_controls_container {

    display: flex;
    flex-direction: row;
    width: 100%;
    height: 182px;
}

.vl_controls_places {

    display: flex;
    flex-direction: row;
    padding: 20px;
    width: 60%;
}

.vl_controls_maps {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 0 20px 20px;
    width: 40%;
}

.vl_controls_maps_edit_button {

    width: 100px;
}