.vl_log_card {

    position: relative;
    width: 97%;
    min-height: 80px;
    padding: 0.5rem;
    margin-bottom: 1.6rem;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 13px;
    border: none;
}

.vl_log_card_selected {

    border-style: solid;
    border-width: 4px;
    border-color: lightgreen;
}

.vl_log_card_blue {

    background: #96C6E8 0 0 no-repeat padding-box;
}

.vl_log_card_orange {

    background: #EBD485 0 0 no-repeat padding-box;
}

.vl_log_card_red {

    background: #F08080 0 0 no-repeat padding-box;
}

.vl_log_card_grey {

    background: #C9C9C9 0 0 no-repeat padding-box;
}

.vl_log_number_and_service {

    position: absolute;
    top: 20%;
    left: 3%;

    width: 28%;
    height: 26%;

    text-align: left;
    font: normal normal normal 19px/23px Adobe Clean;
    letter-spacing: 0;
    color: #414141;
}

.vl_log_time {

    position: absolute;
    top: 60%;
    left: 3%;

    width: 28%;
    height: 26%;

    text-align: left;
    font: normal normal normal 18px/23px Adobe Clean;
    letter-spacing: 0;
    color: #414141;
}

.vl_log_text {

    position: absolute;
    top: 20%;
    left: 35%;

    width: 64%;
    height: 75%;

    text-align: left;
    font: normal normal normal 18px/23px Adobe Clean;
    letter-spacing: 0;
    color: #414141;
}