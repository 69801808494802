.vl_verify_panel {

    /* Layout Properties */

    position: absolute;
    top: 13.3%;
    left: 28.5%;
    width: 43%;
    height: 73.4%;
    min-width: 600px;
    min-height: 600px;

    /* UI Properties */

    background: rgba(0, 0, 0, 0.5) 0 0 no-repeat padding-box;
    border-radius: 23px;
}

.vl_verify_subtitle {

    /* Layout Properties */

    width: 100%;
    height: 2.3vw;
    margin-top: 8vh;
    margin-left: auto;
    margin-right: auto;

    /* UI Properties */

    text-align: center;
    font: normal normal 300 29px/42px Adobe Clean;
    letter-spacing: 0;
    color: #FFFFFF;
}

.vl_verify_error {

    position: absolute;
    color: red;
    bottom: 12.5%;
    left: 18%;

    font: normal normal normal 20px/25px Adobe Clean;
}

.vl_verify_loading_container {

    position: absolute;
    bottom: 12.5%;
    left: 18%;

    width: 20%;
    height: 5%;
}