.vl_select_box {
    
    position: relative;
    font-family: Arial, serif;
    width: 218px;
    height: 39px;
    border-radius: 10px;

    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

    cursor: pointer;
}

.vl_select_box_text {

    height: 100%;
    width: 80%;

    padding: 7px 6px 6px 15px;
    font: normal normal normal 18px/25px Adobe Clean;

    overflow:hidden;
    white-space:nowrap;
}

.vl_select_box_arrow {

    position: absolute;
    width: 24px;
    height: 24px;
    right: 0;
    top: 15px;
    background: transparent url('/src/ui/images/Dropdown.svg') 0 0 no-repeat padding-box;
}

.vl_select_box_body {

    position: absolute;
    top: 39px;
    width: 218px;
    min-height: 25px;
    max-height: 50vh;
    overflow-y: scroll;

    border-bottom: 1px dotted lightgrey;
    border-radius: 10px;

    z-index: 10000;
    background-color: transparent;
}

.vl_select_box_option_container {

    position: relative;
}

.vl_select_box_option {

    position: relative;
    width: 100%;
    height: 36px;

    padding: 4px 4px 4px 10px;

    border-top: 1px dotted lightgrey;
    border-radius: 10px;

    background-color: white;

    overflow:hidden;
    white-space:nowrap;
}

.vl_select_box_option_tooltip {

    position: absolute;
    height: 36px;
    min-width: 100%;
    left: 0;
    top: 0;

    padding: 4px 4px 4px 10px;
    border-radius: 10px;

    z-index: 9999;
    background-color: #e5e5e5;

    font: normal normal normal 16px/25px Adobe Clean;
}

.vl_select_box_option > p {

    height: 100%;
    font: normal normal normal 16px/25px Adobe Clean;
}