.vl_user_menu {

    position: absolute;
    right: 6%;
    top: 65px;
    width: 267px;
    height: 342px;

    /* UI Properties */

    background: white 0 0 no-repeat padding-box;
    box-shadow: 0 0 20px #00000029;
    border-radius: 30px 0 30px 30px;
}

.vl_user_menu_header_container {

    width: 60%;
    height: 25%;
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 9%;

    border-bottom: solid 1px #DADADA;

}

.vl_user_menu_header_username {

    display: flex;

    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    font: normal normal normal 18px/30px Adobe Clean;
}

.vl_user_menu_entry {

    position: relative;
    display: flex;
    flex-direction: row;

    width: 80%;
    height: 15%;
    margin-left: 10%;
    margin-top: 8%;
    padding-top: 15px;

    border-radius: 9px;
}

.vl_user_menu_entry:hover {

    background-color: lightgray;
}


.vl_user_menu_entry:active {

    background-color: var(--common-active);
}

.vl_user_menu_entry p {

    font: normal normal 300 19px/25px Adobe Clean;
}

.vl_user_menu_profile {

    width: 28px;
    height: 27px;
    margin-left: 12%;
    margin-right: 23px;

    background: transparent url('/src/ui/images/Profile.png') 0 0 no-repeat padding-box;
}

.vl_user_menu_users {

    width: 35px;
    height: 27px;
    margin-left: 12%;
    margin-right: 16px;

    background: transparent url('/src/ui/images/ManageUsers.png') 0 0 no-repeat padding-box;
}

.vl_user_menu_logout {

    width: 30px;
    height: 29px;
    margin-left: 12%;
    margin-right: 21px;

    background: transparent url('/src/ui/images/LogOut.png') 0 0 no-repeat padding-box;
}