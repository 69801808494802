.vl_notification_panel_container {

    position: relative;
    width: 100%;
    height: 100px;
    padding: 15px 10px 6px 15px;

    display: flex;
    flex-direction: row;
}

.vl_notification_panel_container_extended {

    position: relative;
    width: 100%;
    height: 200px;
    padding: 15px 10px 6px 15px;

    display: flex;
    flex-direction: row;
}

.vl_notification_panel_container_extended_big {

    position: relative;
    width: 100%;
    height: 310px;
    padding: 15px 10px 6px 15px;

    display: flex;
    flex-direction: row;
}

.vl_notification_panel_underline {

    position: absolute;
    top: 95%;
    left: 10%;

    width: 80%;
    height: 3px;

    margin-top: 5px;

    border-bottom: 1px solid lightgray;
}

.vl_notification_panel_body {

    position: relative;
    height: 100%;
    width: 100%;

    border-radius: 4px;
    padding: 4px;
}

.vl_notification_panel_body:hover {

    background-color: #e5e5e5;
}

.vl_notification_panel_header {

    display: flex;
    height: 80%;
    width: 100%;
    overflow: hidden;

    border-radius: 8px;

    font: normal normal 300 15px/23px Adobe Clean;
    align-items: center;
}

.vl_notification_panel_header_opened {

    height: 45px;
    width: 100%;
    overflow: hidden;

    font: normal normal 300 15px/23px Adobe Clean;
    font-weight: 600;
    align-items: center;
}


.vl_notification_panel_text {

    height: 66%;
    width: 100%;
    overflow: hidden;

    border-radius: 8px;
    margin: 0;

    font: normal normal 300 14px/23px Adobe Clean;
}

.vl_notification_panel_time {

    position: absolute;
    bottom: -2px;
    margin: 0;

    font: normal normal 300 12px/23px Adobe Clean;
    color: rgb(24, 118, 242);
    font-weight: 600;
}

.vl_notification_panel_icon_container {

    width: 50px;
    height: 100%;
}

.vl_notification_panel_error_icon {

    width: 19px;
    height: 19px;
    margin-top: 28px;
    margin-right: 10px;

    background: transparent url('/src/ui/images/NotificationError.svg') 0 0 no-repeat padding-box;
    background-size: cover;
}

.vl_notification_panel_warning_icon {

    width: 19px;
    height: 19px;
    margin-top: 28px;
    margin-right: 10px;

    background: transparent url('/src/ui/images/NotificationWarning.svg') 0 0 no-repeat padding-box;
    background-size: cover;
}

.vl_notification_panel_info_icon {

    width: 19px;
    height: 19px;
    margin-top: 28px;
    margin-right: 10px;

    background: transparent url('/src/ui/images/NotificationInfo.svg') 0 0 no-repeat padding-box;
    background-size: cover;
}