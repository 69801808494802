:root {
    --common-grey: #EFEFEF;
    --common-dark-grey: #7F7F7F;
    --common-active: lightcoral;
    --background-panel: #EFEFEF 0 0 no-repeat padding-box;
}

.vl_background {

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    -webkit-overflow-scrolling: unset;

    background: transparent url('/src/ui/images/Background.png') 0 0 no-repeat padding-box;
    background-size: cover !important;
    opacity: 1;
}

@media screen and (-webkit-min-device-pixel-ratio:0)
and (min-resolution:.001dpcm) {
    .vl_background{ overflow: hidden; }
}
/* Test website on real Chrome version 29 and above */
/* Chrome version 22-28 */
@media screen and(-webkit-min-device-pixel-ratio:0) {
    .vl_background {
        overflow: hidden;
    }
}

.vl_header {

    margin-top: 2%;
    width: 100%;

    text-align: center;
    font: normal normal normal 61px/89px Adobe Clean;
    letter-spacing: 0;
    color: #414141;
}