.vl_scene {

    width: 99%;
    background: #EDEDED 0 0 no-repeat padding-box;
    border-radius: 22px;
    margin-bottom: 1.6vh;
}

.vl_select_scene {

    display: flex;
    justify-content: center;
    align-items: center;

    width: 80%;
    margin-bottom: 1.6vh;
}

.vl_scene_container {

    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10vh;
    min-height: 80px;
}

.vl_select_scene_container {

    display: flex;
    flex-direction: row;
    width: 80%;
    height: 5vh;
    min-height: 50px;

    margin-left: 20px;

    background: #EDEDED 0 0 no-repeat padding-box;
    border-radius: 22px;
}

.vl_scene_info_container {

    padding: 0.4vh 0.7vw 0 0.8vw;
    margin: 1.35vh 12px;
}

.vl_scene_active {

    border-left-style: solid;
    border-color: #009311;
    border-width: 3px;
    border-radius: 2px;
}

.vl_scene_inactive {

    border-left-style: solid;
    border-color: #FFCE00;
    border-width: 4px;
    border-radius: 2px;
}

.vl_scene_name {

    margin-top: 0;
}

.vl_select_scene_name {

    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
}

.vl_scene_info {

    font-size: 1rem;
    margin-top: 1.8vh;
}

.vl_scene_edit_button {

    margin: 3.8vh 2vw 3.8vh 1vw;
    width: 1.6vw;
    height: 3vh;
    border: none;
}

.vl_scene_edit_button:hover {

    background-color: transparent;
}

.vl_scene_edit_button:active {

    background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.5));
    border-radius: 15px;
}

.vl_scene_trigger_container {

    position: relative;
    width: 6vw;
    border-left: solid 2px lightgray;
    margin: 2vh 0 2vh 0;
}

.vl_scene_trigger_button {

    position: absolute;
    background: transparent url('/src/ui/images/RedTrigger.svg') 0 0 no-repeat padding-box;
    background-size: cover;
    width: 3vw;
    height: 6vh;
    left: 1vw;
    border: none;
}

.vl_scene_edit_show_realtime_container {

    display: flex;
    flex-direction: row;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    margin-top: 1vh;
    border-bottom: 2px dotted lightgrey;
}

.vl_scene_show_realtime_checkbox {

}

.vl_scene_show_realtime_text {

    margin-left: 10px;
    font: normal normal normal 15px/25px Adobe Clean;
}

.vl_scene_power_button {

    position: absolute;
    width: 56px;
    height: 56px;
    left: 1vw;

    background-color: transparent;
    border: none;
    padding: 0;
}

.vl_scene_power_button:hover {

    background-color: transparent;
}

.vl_scene_power_button:active {

    border-radius: 30px;
    background: radial-gradient(rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0.5));
}