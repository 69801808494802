.vl_dialog {

    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    align-items: center;
    justify-content: center;
    z-index: 9999;
}