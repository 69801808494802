.vl_code_input {

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.vl_code_input_field {

    width: 70px;
    height: 35px;
    margin-right: 15px;
    margin-left: 15px;

    border: none;
    background: transparent;

    text-align: center;
    font: normal normal 300 42px/52px Adobe Clean;
}

.vl_code_input_field_white {

    border-bottom: 2px solid rgba(255, 255, 255, 0.4);
    color: white;
}

.vl_code_input_field_black {

    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
    color: black;
}