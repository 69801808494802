.vl_activation_scene_by_sensor_info_container {

    position: relative;
    padding: 0.4vh 0.7vw 0 0.8vw;
    margin: 1.35vh 0.6vw;
    width: 70%;
}

.vl_activation_scene_by_sensor_name {

    margin-top: 0;
}