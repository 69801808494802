.vl_not_available_panel_container {

    width: 100%;
    height: 400px;
    padding-top: 80px;
}

.vl_not_available_panel_icon {

    position: relative;
    width: 200px;
    height: 200px;

    margin-left: 50%;
    transform: translate(-50%);
    margin-bottom: 40px;

    background: transparent url('/src/ui/images/NotAvailable.svg') 0 0 no-repeat padding-box;
    background-size: contain;
}

.vl_not_available_panel_text {

    position: relative;
    
    width: 60%;
    margin-left: 50%;
    transform: translate(-50%);

    font: normal normal normal 28px/42px Adobe Clean;
    letter-spacing: 0;
    text-align: center;
}