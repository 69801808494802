.vl_edit_button {

    height: 40px;
    border: none;
    border-radius: 25px;
    background-color: transparent;

    display: flex;
    flex-direction: row;
}

.vl_edit_button:active {

    color: var(--common-active);
}

.vl_edit_button_text {

    text-align: left;
    font: normal normal 300 18px/25px Adobe Clean;
}

.vl_edit_button_icon {

    width: 24px;
    height: 24px;
    margin-left: 10px;
    background: transparent url('/src/ui/images/EditFeather.svg') 0 0 no-repeat padding-box;
    background-size: cover;
}