.vl_file_box {

    display: flex;
    flex-direction: column;
    border: 3px dashed #707070;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
}

.vl_file_box_dragging {

    border-color: orange !important;
}

#vl_file_box_input {

    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: green;
}

.vl_file_box_cloud {

    width: 150px;
    height: 150px;

    background: transparent url('/src/ui/images/Cloud.svg') 0 0 no-repeat padding-box;
    background-size: contain;
}

.vl_file_box_text {

    font: normal normal normal 18px/25px Adobe Clean;
}