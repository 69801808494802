.vl_users_panel {

    /* Layout Properties */

    position: absolute;
    top: 20vh;
    left: 23.5vw;
    width: 53vw;
    height: 69vh;
    min-height: 550px;
    z-index: 1;

    /* UI Properties */

    background-color: var(--common-grey);
    box-shadow: 0 3px 20px #00000029;
    border-radius: 30px;
    opacity: 1;

    display: flex;
    flex-direction: row;
}

.vl_users_panel[aria-disabled] {

    background-color: red;
}