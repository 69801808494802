.vl_slider_container_vertical {

    position: relative;
    width: 30px;
}

.vl_slider_container_horizontal {

    position: relative;
    height: 30px;
}

.vl_slider_track_vertical {

    position: absolute;

    top: 10px;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);

    width: 4px;
    border-radius: 2px;

    background-color: grey;
}

.vl_slider_track_horizontal {

    position: absolute;

    right: 10px;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);

    height: 4px;
    border-radius: 2px;

    background-color: grey;
}

.vl_slider_thumb_vertical {

    position: absolute;

    left: 50%;
    transform: translate(-50%);

    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: lightgrey;
    z-index: 9999;
}

.vl_slider_thumb_horizontal {

    position: absolute;

    top: 50%;
    transform: translate(0, -50%);

    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: lightgrey;
    z-index: 9999;
}