.vl_profile_2fa_approval_panel {

    width: 100%;
    height: 60%;
}

.vl_profile_2fa_approval_approved_icon {

    width: 260px;
    height: 260px;
    margin-top: 8%;
    margin-left: auto;
    margin-right: auto;

    background: transparent url('/src/ui/images/Approved.svg') 0 0 no-repeat padding-box;
    background-size: contain;
}

.vl_profile_2fa_approval_approved_icon_animation {

    width: 80%;
    height: 100%;
    margin-left: 10%;
}

.vl_profile_2fa_approval_title {

    width: 100%;

    margin-top: 16%;
    text-align: center;
    font: normal normal normal 34px/46px Adobe Clean;
}