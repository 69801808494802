.vl_edit_container {

}

.vl_edit_input {

    background-color: white;
    /* UI Properties */

    border: 1px solid #707070;
    border-radius: 15px;
    padding-left: 0.8rem;

    width: 100%;
    height: 100%;

    font: normal normal 300 17px/25px Adobe Clean;
}