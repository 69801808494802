:root {

    --intensity_box_radius: 32px;
    --intensity_box_z_index: 10;
    --intensity_box_font: normal normal normal 14px/18px Helvetica Neue;
    --intensity_box_thumb_width: 1.1vw;
    --intensity_box_thumb_height: 2vh;

    --intensity_box_width: 3.5vw;
    --intensity_box_height: 19vh;
    --intensity_box_track_width: 0.6vw;
    --intensity_box_track_height: 11vh;
    --intensity_box_left: calc(var(--intensity_box_width) / 2 - var(--intensity_box_track_width) / 2);
    --intensity_box_thumb_left: calc(var(--intensity_box_width) / 2 - var(--intensity_box_thumb_width) / 2);
    --intensity_box_track_background: transparent linear-gradient(180deg, #FFC400 0%, #CBCBCB 100%) 0 0 no-repeat padding-box;

    --intensity_box_width_h: 12.6vw;
    --intensity_box_height_h: 6.5vh;
    --intensity_box_track_width_h: 8vw;
    --intensity_box_track_height_h: 1.1vh;
    --intensity_box_top_h: calc(var(--intensity_box_height_h) / 2 - var(--intensity_box_track_height_h) / 2);
    --intensity_box_thumb_top_h: calc(var(--intensity_box_height_h) / 2 - var(--intensity_box_thumb_height) / 2);
    --intensity_box_track_background_h: transparent linear-gradient(270deg, #FFC400 0%, #CBCBCB 100%) 0 0 no-repeat padding-box;
}

.vl_intensity_box_container {

    position: absolute;

    width: var(--intensity_box_width);
    height: var(--intensity_box_height);

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 15px #0000005C;
    border-radius: 32px;

    z-index: 10;
}

.vl_intensity_box_container_horizontal {

    position: absolute;

    width: var(--intensity_box_width_h);
    height: var(--intensity_box_height_h);

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 15px #0000005C;
    border-radius: 32px;

    z-index: 10;
}

.vl_intensity_box_slider_track {

    position: absolute;
    top: 3vh;
    left: var(--intensity_box_left);
    width: var(--intensity_box_track_width);
    height: var(--intensity_box_track_height);

    background: #A2A2A2 0 0 no-repeat padding-box;
    border-radius: 5px;
}

.vl_intensity_box_slider_track_horizontal {

    position: absolute;
    top: var(--intensity_box_top_h);
    left: 3vw;
    width: var(--intensity_box_track_width_h);
    height: var(--intensity_box_track_height_h);

    background: #A2A2A2 0 0 no-repeat padding-box;
    border-radius: 5px;
}

.vl_intensity_box_slider_passed_track {

    position: absolute;
    left: var(--intensity_box_left);
    width: 0.6vw;

    background: var(--intensity_box_track_background);
    border-radius: 10px;
}

.vl_intensity_box_slider_passed_track_horizontal {

    position: absolute;
    top: var(--intensity_box_top_h);
    left: 3vw;
    height: 1.1vh;

    background: var(--intensity_box_track_background_h);
    border-radius: 10px;
}

.vl_intensity_box_slider_thumb {

    position: absolute;
    left: var(--intensity_box_thumb_left);
    width: var(--intensity_box_thumb_width);
    height: var(--intensity_box_thumb_height);
    border-radius: 12px;

    /* UI Properties */

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 6px #00000029;
}

.vl_intensity_box_slider_thumb_horizontal {

    position: absolute;
    top: var(--intensity_box_thumb_top_h);
    width: var(--intensity_box_thumb_width);
    height: var(--intensity_box_thumb_height);
    border-radius: 12px;

    /* UI Properties */

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 6px #00000029;
}

.vl_intensity_box_label {

    position: absolute;

    bottom: -0.1vh;
    left: 0;
    width: 100%;

    text-align: center;
    font: var(--intensity_box_font);
    letter-spacing: 0;
    color: #4D4D4D;
}

.vl_intensity_box_label_horizontal {

    position: absolute;

    top: calc(var(--intensity_box_thumb_top_h) + 2px);
    left: 0.6vw;

    text-align: center;
    font: var(--intensity_box_font);
    letter-spacing: 0;
    color: #4D4D4D;
}