#vl_titlebar {

    /* Layout Properties */

    position: absolute;
    left: 5vw;
    width: 90vw;
    height: 66px;
    min-width: 900px;
    z-index: 9998;

    /* UI Properties */

    opacity: 1;
}

.vl_division {

    position: absolute;
    height: 56px;
    width: 1%;
    left: 200px;
    top: 10px;

    border-left-style: solid;
    border-width: 2px;
    border-color: rgba(200, 200, 200, 0.3);
}

.vl_logo {

    /* Layout Properties */

    position: absolute;
    top: 8px;
    left: 26px;
    width: 99px;
    height: 42px;

    /* UI Properties */

    background: transparent url('/src/ui/images/Logo.svg') 0 0 no-repeat padding-box;
    background-size: contain;
    opacity: 1;
}

.vl_title {

    /* Layout Properties */

    position: absolute;
    top: 49px;
    left: -45px;
    width: 200px;
    height: 24px;

    /* UI Properties */

    background: url('/src/ui/images/VISIBLE_CONNECT.svg') no-repeat center;
    background-size: 100% 72%;
    opacity: 1;
}

.vl_live_status {

    position: absolute;
    top: 26px;
    left: 220px;
}

.vl_page {

    /* Layout Properties */

    position: absolute;
    top: 24px;
    left: 265px;
    width: 150px;
    height: 32px;

    /* UI Properties */

    background: transparent;
    background-size: 105% 75%;
    opacity: 1;

    color: white;
    text-align: left;
    font: normal normal normal 22px/30px Adobe Clean;
    letter-spacing: 0;
}

.vl_time {
    /* Layout Properties */

    position: absolute;
    top: 18px;
    left: 41%;
    width: 94px;
    height: 51px;

    /* UI Properties */

    opacity: 1;
    color: white;
}

.vl_date {
    /* Layout Properties */

    position: absolute;
    top: 18px;
    left: 48.5%;
    width: 146px;
    height: 51px;

    /* UI Properties */

    opacity: 1;
    color: white;
}

.vl_notifications {

    /* Layout Properties */

    position: absolute;
    top: 33px;
    right: 10%;
    width: 16px;
    height: 20px;

    /* UI Properties */

    background: transparent url('/src/ui/images/Notifications.svg') 0 0 no-repeat padding-box;
    border: none;
    opacity: 1;
}

.vl_notifications_hovered {

    /* Layout Properties */

    position: absolute;
    top: 33px;
    right: 10%;
    width: 20px;
    height: 20px;

    background: transparent;

    border: none;
    opacity: 1;
}

.vl_notifications_unseen_sign {

    position: absolute;
    right: -7px;
    top: -3px;
    width: 13px;
    height: 13px;
    border-radius: 7px;
    background-color: #FF4646;

    z-index: 9999;
}

.vl_notifications_animation_container {

    width: 100%;
    height: 100%;
}

.vl_user {

    /* Layout Properties */

    position: absolute;
    top: 33px;
    right: 6%;
    width: 20px;
    height: 20px;

    /* UI Properties */

    background: transparent url('/src/ui/images/User.svg') 0 0 no-repeat padding-box;
    border: none;
    opacity: 1;
}

.vl_menu {

    /* Layout Properties */

    position: absolute;
    top: 29px;
    right: 0;
    width: 30px;
    height: 28px;

    /* UI Properties */

    background: transparent url('/src/ui/images/Menu.svg') 0 0 no-repeat padding-box;
    border: none;
    opacity: 1;
}

@media(max-width: 1400px) {

    .vl_time {
        display: none;
    }

    .vl_date {
        /* Layout Properties */
        left: 45%;
    }
}