.vl_sensor_container {

    display: flex;
    flex-direction: row;
}

.vl_sensor {

    display: flex;
    flex-direction: row;
    position: relative;
    width: 544px;
    height: 66px;
    background-color: #d2e2ff;
    border-radius: 29px;
    margin-bottom: 10px;
}

.vl_sensor_occupancy_icon {

    position: relative;
    height: 45px;
    width: 50px;
    top: 9px;
    left: 20px;
    margin-right: 20px;
    background: transparent url('/src/ui/images/Occupancy.png') 0 0 no-repeat padding-box;
    background-size: cover
}

.vl_sensor_button_icon {

    position: relative;
    height: 50px;
    width: 50px;
    top: 9px;
    left: 20px;
    margin-right: 20px;
    background: transparent url('/src/ui/images/Button.png') 0 0 no-repeat padding-box;
    background-size: cover
}

.vl_sensor_unknown_icon {

    position: relative;
    height: 50px;
    width: 50px;
    top: 9px;
    left: 20px;
    margin-right: 20px;
    background: transparent url('/src/ui/images/Unknown.png') 0 0 no-repeat padding-box;
    background-size: cover;
}

.vl_sensor_info_container {

    padding: 10px 10px 10px 20px;
}

.vl_sensor_name {

    margin-bottom: 0;
    cursor: pointer;
}

.vl_sensor_name_edit {

    width: 100%;
    height: 32px;
    margin-top: -8px;
    margin-bottom: 5px;
}

.vl_sensor_name_edit_input {

    margin-top: 11px;
    width: 80% !important;
    height: 50% !important;
    border-bottom: 1px solid grey !important;
    font: normal normal 300 14px/25px Adobe Clean !important;
}

.vl_sensor_name_edit_error {

    bottom: -30px !important;
    font: normal normal 300 13px/25px Adobe Clean !important;
}

.vl_sensor_info {

    font-size: 0.8rem;
    margin-right: 1.2rem;
}

.vl_sensor_info_table_container {

    display: flex;
    flex-direction: row;

    width: 100%;
    height: 235px;
    overflow-y: scroll;
}

.vl_sensor_info_table {

    width: 90%;
    margin-left: 5%;
    margin-bottom: 15px;
    color: #212529;
    vertical-align: top;

    font: normal normal 300 13px/25px Adobe Clean;
}

.vl_sensor_info_table_row {

    border-bottom: 2px dotted #777777;
}

.vl_sensor_info_table_general_entry {

    font: normal normal 300 16px/25px Adobe Clean !important;
    font-weight: bold !important;
}

.vl_sensor_info_table_item_title {

    padding: 15px;
    text-align: right;
    border-right: 2px dotted #777777;

    width: 44%;
}

.vl_sensor_info_table_item_value {

    padding: 15px;
    text-align: left;

    width: 56%;
}

.vl_sensor_buttons {

    display: flex;
    flex-direction: row;
    padding: 8px;
    margin-left: auto;
    margin-right: 5px;
}

.vl_sensor_check_box_container {

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 12px;
    margin-right: 14px;
}