.vl_deleted_devices {

    position: relative;
}

.vl_deleted_devices_header {

    position: absolute;
    top: 2vh;
    right: 2.7vw;
    width: 14vw;
    height: 5vh;
    min-width: 200px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 -5px 5px #0000005C;
    z-index: 2;
}

.vl_deleted_devices_header_text {

    position: absolute;
    width: 100%;
    top: 1.2vh;

    text-align: center;
    font: normal normal 300 19px/25px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_deleted_devices_main {

    position: absolute;
    top: 7vh;
    width: 35vw;
    height: 55vh;
    min-width: 450px;
    min-height: 400px;
    border-radius: 25px;
    margin-left: 18px;

    /* UI Properties */

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 15px #0000005C;
    z-index: 1;
}

.vl_deleted_devices_list_container {

    position: relative;
    top: 70px;
    width: 100%;
    height: 60%;

    overflow-y: scroll;
}

.vl_deleted_devices_select_error {

    position: absolute;
    top: 10px;
    width: 100%;

    text-align: center;

    color: red;
    font: normal normal 300 17px/25px Adobe Clean;
}