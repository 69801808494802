.vl_send_to_help_dialog {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 470px;
    height: 470px;

    /* UI Properties */

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 20px #00000029;
    border-radius: 30px;

    z-index: 10000;
}

.vl_send_to_help_dialog_text {

    position: absolute;
    top: 40px;
    left: 45px;
    width: 380px;

    font: normal normal normal 21px/32px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_send_to_help_dialog_input {

    position: absolute;
    top: 130px;
    left: 45px;
    width: 380px;

    font: normal normal normal 21px/32px Adobe Clean;
}

.vl_send_to_help_dialog_input_notes {

    position: absolute;
    top: 185px;
    left: 45px;

    width: 380px;
    height: 200px;

    resize: none;

    font: normal normal normal 19px/32px Adobe Clean;
}

.vl_send_to_help_dialog_yes {

    position: absolute;

    bottom: 17px;
    left: 50%;
    transform: translate(15%, 0);
}

.vl_send_to_help_dialog_no {

    position: absolute;

    bottom: 17px;
    left: 50%;
    transform: translate(-115%, 0);
}