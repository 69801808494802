.vl_group {

    position: relative;
    min-width: 544px;
    background-color: transparent;
    margin: 0 40px 10px;
    border-radius: 20px;
}

.vl_group_active {

    background-color: #FFF8A3 !important;
    border-radius: 29px;
}

.vl_group_container {

    display: flex;
    flex-direction: row;
    position: relative;
}

.vl_group_status_ok {

    position: relative;
    height: 25px;
    width: 70px;
    top: 20px;
    left: 25px;
    background: transparent url('/src/ui/images/Available.png') 0 0 no-repeat padding-box;
}

.vl_group_status_error {

    position: relative;
    height: 35px;
    width: 70px;
    top: 14px;
    left: 16px;
    background: transparent url('/src/ui/images/Error.svg') 0 0 no-repeat padding-box;
}

.vl_group_name {

    padding: 20px;
    cursor: default;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.vl_group_buttons {

    display: flex;
    flex-direction: row;
    position: absolute;
    padding: 8px;
    right: 1vw;
    width: 50%;
}

.vl_group_loading_container {

    position: absolute;
    top: 5%;
    right: 25%;

    width: 20%;
    height: 5%;
}

.vl_group_name_edit {

    padding: 10px 10px 12px 10px;
    width: 35%;
}

.vl_group_name_edit_input {

    margin-top: 11px;
    width: 80% !important;
    height: 50% !important;
    border-bottom: 1px solid lightgrey !important;
    font: normal normal 300 14px/25px Adobe Clean !important;
}