.vl_tabs {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
}

.vl_tab_buttons_container {


}

.vl_tab_button, .vl_tab_button:hover {

    border: none;
    background-color: var(--common-grey);
    font-size: 19px;
    height: 2.8rem;
    width: 7vw;
}

.vl_tab_set_button, .vl_tab_set_button:hover {

    border: none;
    background-color: var(--common-grey);
    font-size: 19px;
    height: 5.5vh;
    min-width: 7.5vw;
}

.vl_tab_button_selected, .vl_tab_button_selected:hover  {

    font-size: 19px;
    font-weight: bold;
    height: 2.8rem;
    width: 7vw;
    min-width: 70px;
    z-index: 10;

    border-style: none;
    border-bottom-style: solid;
    border-width: 3px;
    border-color: orange;
    background-color: var(--common-grey);
}

.vl_tab_set_button_selected, .vl_tab_set_button_selected:hover  {

    border: none;
    font-size: 19px;
    font-weight: bold;
    height: 5.5vh;
    min-height: 30px;
    min-width: 7.5vw;
    z-index: 10;

    background: #41AA39 0 0 no-repeat padding-box;
    box-shadow: 0 3px 9px #00000057;
    border-radius: 29px;
    color: white;
}

.vl_tabs_underline {

    position: relative;
    height: 2px;
    width: 100%;
    background-color: lightgray;
    bottom: 0;
    z-index: 1;
}