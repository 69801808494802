.vl_activation_scene_by_date_info_container {

    position: relative;
    padding: 0.4vh 0.7vw 0 0.8vw;
    margin: 1.35vh 0.6vw;
    width: 70%;
}

.vl_activation_scene_by_date_name {

    margin-top: 0;
}

.vl_activation_scene_by_date_active {

    border-left-style: solid;
    border-color: #009311;
    border-width: 3px;
    border-radius: 2px;
}

.vl_activation_scene_by_date_inactive {

    border-left-style: solid;
    border-color: #FFCE00;
    border-width: 4px;
    border-radius: 2px;
}

.vl_activation_scene_by_date_days {

    position: absolute;
    top: 70%;
    left: 2vw;
    width: 70%;
    height: 30%;

    display: flex;
    flex-direction: row;
}

.vl_activation_scene_by_date_days p {

    text-align: center;
    font: normal normal normal 17px/23px Adobe Clean;
    letter-spacing: 0;
    color: #414141;
    margin-right: 12px;
}

.vl_activation_scene_by_date_time {

    position: absolute;
    font: normal normal normal 19px/23px Adobe Clean;
    letter-spacing: 0;
    color: #414141;
    text-align: center;

    top: 10%;
    left: 55%;
}

.vl_activation_scene_by_date_time > span {

    position: absolute;
    width: 160px;
    height: 25px;

    left: -50px;
    top: 100%;

    font: normal normal normal 17px/23px Adobe Clean;
    letter-spacing: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    border-radius: 10px;

    z-index: 9999;
}

.vl_activation_scene_by_date_active_container {

    width: 20%;
    height: 6vh;
    margin-top: 2vh;
    margin-left: 4%;
    padding-top: 1.5vh;
    padding-left: 1.2vw;

    border-left: solid 1px #777;
}

.vl_activation_scene_by_date_edit_container {

    position: relative;
    width: 100%;
    height: 17.5vh;
    min-height: 160px;
}

.vl_activation_scene_by_date_edit_container_long {

    position: relative;
    width: 100%;
    height: 45vh;
    min-height: 420px;
}

.vl_activation_scene_by_date_edit_time {

    display: flex;
    justify-content: center;

    width: 100%;
    font: normal normal normal 32px/36px Adobe Clean;
    letter-spacing: 0;
    color: #414141;
}

.vl_activation_scene_by_date_edit_time_gmt {

    display: flex;
    justify-content: center;

    height: 24px;

    width: 100%;
    font: normal normal normal 18px/32px Adobe Clean;
    letter-spacing: 0;
    color: #414141;
}

.vl_activation_scene_by_date_repeat_every_tab {

    position: relative;
    margin-top: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.vl_activation_scene_by_date_repeat_every_tab_item {

    display: flex;
    flex-direction: row;
    width: 4vw;
    margin-right: 0.5vw;
}

.vl_activation_scene_by_date_repeat_every_tab_item p {

    margin-left: 0.3vw;
}

.vl_activation_scene_by_date_scenes_list_tab {

    position: relative;
    top: 30px;
    width: 100%;
    height: 320px;
}

.vl_activation_scene_by_date_scenes_list_tab div {

}

.vl_activation_scene_by_date_one_time_tab {

    position: relative;
    width: 100%;
    height: 320px;

}

.vl_activation_scene_by_date_one_time_tab_calendar {

    width: 500px !important;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%);
}

.vl_activation_scene_by_date_one_time_dates_list {

    position: absolute;
    top: 30px;
    left: 55%;

    width: 200px;
    height: 220px;

    border: 2px dotted lightgrey;
    border-radius: 10px;

    overflow-y: scroll;
    z-index: 100;
}

.vl_activation_scene_by_date_one_time_dates_list > p {

    position: relative;
    left: 10%;

    width: 80%;
    height: 32px;
    padding: 3px;

    margin-top: 14px;
    margin-bottom: 0;

    border-bottom: 1px solid lightgrey;

    font: normal normal normal 17px/25px Adobe Clean;
}

.vl_activation_scene_by_date_one_time_dates_list > h5 {

    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    width: 100%;
    text-align: center;

    color: var(--common-dark-grey);
    font: normal normal normal 20px/28px Adobe Clean;
}