.vl_scenes_list {

    margin-top: 4vh;
    overflow-y: scroll;
    height: 50vh;
}

.vl_scenes_list_loading_container {

    position: absolute;

    width: 100%;
    height: 100%;
    z-index: 9999;
}