.vl_add_group {

    position: relative;
}

.vl_add_group_header {

    position: absolute;
    top: 2vh;
    right: 2.7vw;
    width: 14vw;
    height: 5vh;
    min-width: 200px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 -5px 5px #0000005C;
    z-index: 2;
}

.vl_add_group_header_text {

    position: absolute;
    top: 1.2vh;
    left: 2.1vh;

    text-align: center;
    font: normal normal 300 19px/25px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_add_group_main {

    position: absolute;
    top: 7vh;
    width: 35vw;
    height: 66vh;
    min-width: 450px;
    min-height: 400px;
    border-radius: 25px;

    /* UI Properties */

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 15px #0000005C;
    z-index: 1;
}

.vl_add_group_main_name_edit {

    position: relative;
    top: 25px;
    left: 30px;

    width: 80%;
    height: 4.7vh;
}

.vl_add_group_choose_header {

    position: relative;
    top: 65px;
    left: 10%;
    width: 80%;

    margin-bottom: 30px;

    border-bottom: 1px solid lightgrey;

    text-align: center;
    font: normal normal 300 19px/25px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
    opacity: 0.5;
}

.vl_add_group_devices_list_container {

    position: relative;
    top: 70px;
    width: 100%;
    height: 60%;

    overflow-y: scroll;
}

.vl_add_group_name_error {

    position: absolute;
    top: 70px;
    left: 40px;

    color: red;
    font: normal normal 300 17px/25px Adobe Clean;
}

.vl_add_group_select_error_container {

    position: relative;
    top: 35px;
}

.vl_add_group_select_error {

    position: absolute;
    top: 10px;
    width: 100%;

    text-align: center;

    color: red;
    font: normal normal 300 17px/25px Adobe Clean;
}