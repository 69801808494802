.vl_info_button {

    width: 50px;
    height: 50px;
    border: none;
    border-radius: 25px;
    background-color: transparent;
}

.vl_info_button:active {

    background-color: rgb(0, 0, 0, 0.05);
}

.vl_info_button_icon {

    width: 32px;
    height: 32px;
    margin-left: 5px;
    margin-top: 9px;
    background: transparent url('/src/ui/images/Info.png') 0 0 no-repeat padding-box;
}