.vl_regular_button {

    width: 160px;
    height: 50px;

    /* UI Properties */
    background: #FFC440 0 0 no-repeat padding-box;
    border-radius: 26px;
    border: none;
    opacity: 1;

    text-align: center;
    font: normal normal 300 18px/25px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_regular_button:active {

    background-color: lightcoral;
}

.vl_negative_button {

    width: 160px;
    height: 50px;

    /* UI Properties */

    background: #B1B1B1 0 0 no-repeat padding-box;
    border-radius: 26px;
    border: none;
    opacity: 1;

    text-align: center;
    font: normal normal 300 18px/25px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_negative_button:active {

    background-color: lightcoral;
}

.vl_empty_button {

    width: 160px;
    height: 50px;

    /* UI Properties */
    background:transparent;
    border: none;
    opacity: 1;

    text-align: center;
    font: normal normal 300 18px/25px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_empty_button:active {

    color: rgba(255, 0, 0, 0.4);
}

.vl_button_delete_icon {

    background: transparent url('/src/ui/images/Delete.svg') 120% 60% no-repeat padding-box;
    background-size: 65px 30px;
}

.vl_button_close_icon {

    /*width: 20px !important;*/
    /*height: 20px !important;*/

    background: transparent url('/src/ui/images/Close.svg') 9px 0 no-repeat padding-box;
    background-size: 24px 24px !important;
}

.vl_button_back_icon {

    background: transparent url('/src/ui/images/back.png') 100% 60% no-repeat padding-box;
    background-size: 45px 30px;
}
