.vl_add_map_dialog {

    position: fixed;
    display: flex;
    flex-direction: row;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 958px;
    height: 569px;

    /* UI Properties */

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 20px #00000029;
    border-radius: 30px;

    z-index: 10000;
}

.vl_add_map_dialog_extended {

    height: 650px !important;
}

.vl_add_map_dialog_file_upload_container {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 554px;
    height: 355px;

    /* UI Properties */

    display: flex;
    flex-direction: column;
    border: 3px dashed #707070;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
}

.vl_add_map_dialog_file_display_container {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 750px;
    height: 600px;

    /* UI Properties */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vl_add_map_dialog_file_display_container > img {

    height: 65%;
    width: 100%;
}

.vl_add_map_dialog_bound_setup_container {

    position: absolute;
    top: 10%;
    right: 60px;
    width: 470px;
    height: 70%;
}

.vl_add_map_dialog_naming_input {

    border: none;
    border-bottom: 1px solid #707070;
    padding-left: 15px;
    margin-top: 5%;

    width: 400px;
    height: 40px;

    font: normal normal 300 17px/25px Adobe Clean;
}

.vl_add_map_dialog_error {

    position: relative;
    width: 400px;

    color: red;
    font: normal normal 300 13px/16px Adobe Clean;
}

.vl_add_map_dialog_close_button {

    position: absolute;
    right: 3%;
    top: 5%;
    width: 42px;
    height: 42px;
    background-size: contain;
}

.vl_add_map_back_button {

    position: absolute;
    left: 3%;
    top: 4%;
    width: 42px;
    height: 42px;
    background-size: contain;
}

#vl_add_map_dialog_file_upload {

    display: none;
}

.vl_add_map_map_container {

    flex: 1 1 auto;
    padding: 1rem 1rem;
    height: 566px;
    border: 2px solid black;
}