.vl_filter {

    display: flex;
    flex-direction: row;
    background-color: var(--background-panel);
}

.vl_sort_icon {

    position: relative;
    top: 10px;
    left: 12px;
    width: 28px;
    height: 20px;

    margin-right: 40px;
    border: none;
    background: transparent url('/src/ui/images/Sort.svg') no-repeat center;
}

.vl_sort_activated_icon {

    position: relative;
    top: 10px;
    left: 12px;
    width: 28px;
    height: 20px;

    margin-right: 40px;
    border: none;
    background: transparent url('/src/ui/images/Sort.svg') no-repeat center;
    transform: rotate(180deg) scaleX(-1);
}

.vl_sort_icon:active,.vl_sort_activated_icon:active {

    background: transparent no-repeat center;
    background-image: url('/src/ui/images/Sort.svg'), radial-gradient(rgba(0, 0, 0, 0.12), rgba(255, 255, 255, 0.5));
}