.vl_date_edit_container {

    position: relative;
    display: flex;
    flex-direction: row;

    width: 218px;
    background-color: white;
    border-radius: 10px;
}

.vl_date_edit_year {

    border: none;
    border-radius: 10px;

    width: 40%;
    height: 100%;

    text-align: center;
    font: normal normal 300 22px/28px Adobe Clean;
}

.vl_date_edit_month {

    border: none;

    width: 30%;
    height: 100%;

    text-align: center;
    font: normal normal 300 22px/28px Adobe Clean;
}

.vl_date_edit_day {

    border: none;
    border-radius: 10px;

    width: 30%;
    height: 100%;

    text-align: center;
    font: normal normal 300 22px/28px Adobe Clean;
}

.vl_date_edit_invalid_input {

    background-color: lightcoral;
}

.vl_date_edit_divider {

    width: 15px;
    height: 100%;

    text-align: center;

    font: normal normal 300 24px/30px Adobe Clean;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

}