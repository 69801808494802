.vl_custom_report {

    position: relative;
    display: flex;
    flex-direction: row;
    
    height: 115px;
    background-color: #DADADA;
    border-radius: 15px;
    margin-bottom: 10px;
}

.vl_custom_report_main {

    width: 94%;
}

.vl_custom_report_entity {

    display: flex;
    flex-direction: row;

    padding-bottom: 12px;
    width: 100%;
    height: 50%;
}

.vl_custom_report_interval {

    display: flex;
    flex-direction: row;

    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    height: 50%;
}

.vl_custom_report_remove_icon {

    width: 5%;
    height: 50%;
    margin-top: 5px;
    margin-bottom: auto;
    cursor: pointer;

    background: transparent url('/src/ui/images/Delete.svg') 50% 50% no-repeat padding-box;
    background-size: cover;
}
