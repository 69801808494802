.vl_add_custom_report {

    position: relative;

    height: 115px;
    border-radius: 15px;
    border: 2px dotted grey;
    cursor: pointer;
}

.vl_add_custom_report:active {

    border-color: red;
}

.vl_add_custom_report_icon {

    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: transparent url('/src/ui/images/Add.png') 0 0 no-repeat padding-box;
    background-size: cover;
}