.vl_custom_reports_tab {

    position: relative;
    height: 70%;
}

.vl_custom_reports_tab_generate {

    position: absolute;

    bottom: 5%;
    left: 75%;
    transform: translate(15%, 0);
}

.vl_custom_reports_charts_list_close_icon {

    position: absolute;
    top: 5px;
    right: 20px;

    width: 42px;
    height: 42px;
    cursor: pointer;

    background: transparent url('/src/ui/images/Delete.svg') no-repeat padding-box;
    background-size: cover;

    z-index: 9999;
}
