.vl_map_footer {

    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;

    height: 8%;
}

.vl_map_footer_delete_button {

    height: 40px;
    width: 110px;
    border: none;

    background-color: var(--common-grey);
    font: normal normal normal 16px/28px Adobe Clean;
    text-decoration: underline;
    color: royalblue;
}