.vl_area {

    width: 100%;
    margin-top: 0;
}

.vl_area_active {

    background-color: #FFF8A3 !important;
}

.vl_area_container {

    position: relative;
    display: flex;
    flex-direction: row;
}

.vl_area_name {

    padding: 20px 20px 20px 30px;
    cursor: default;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.vl_area_name_edit {

    padding: 10px 15px 12px 30px;
    width: 50%;
}

.vl_area_name_edit_input {

    margin-top: 13px;
    height: 50% !important;
    border-bottom: 1px solid lightgrey !important;
}

.vl_area_place {

    position: absolute;
    right: 10px;
    top: 20px;
    width: 170px;

    text-align: center;
    color: #009142;

    font: normal normal 300 17px/25px Adobe Clean;
}

.vl_area_add_group_container {

    width: 100%;
    height: 50px;
}

.vl_area_add_group_button {

    width: 170px !important;
    float: right;
    margin-right: 45px;
}