.vl_power_consumption_tab {

    height: 70%;
}

.vl_power_consumption_info_bar {

    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
    margin-top: 2%;
}

.vl_power_consumption_title {

    margin-left: 7.5%;
    margin-right: auto;
    width: 35%;

    text-align: left;
    font: normal normal normal 21px/32px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_power_consumption_chart_container {

    margin-left: 7.5%;
    margin-right: 7.5%;
    margin-top: 20px;
    height: 90%;
}