.vl_live_status_container {

    width: 26px;
    height: 26px;
    /*background-color: red;*/
}

.vl_live_status_ok {

    width: 100%;
    height: 100%;

    background: transparent url('/src/ui/images/Connected.svg') 0 0 no-repeat padding-box;
    background-size: cover;
    opacity: 1;
}

.vl_live_status_error {

    width: 100%;
    height: 100%;

    background-size: 130% 140%;
    background: transparent url('/src/ui/images/Error.svg') no-repeat center;
    background-position-x: center;
    background-position-y: -6px;
    opacity: 1;
}

.vl_live_status_tooltip {

    position: absolute;
    top: 20px;
    left: 20px;

    height: 25px;

    /*border: 1px solid black;*/
    border-radius: 0 8px 8px 8px;
    background-color: white;

    font: normal normal 300 17px/25px Adobe Clean;
    text-align: center;

    z-index: 9999;
}