.vl_add_by_date {

    position: relative;
}

.vl_add_by_date_header {

    position: absolute;
    right: 2.2vw;
    width: 9.5vw;
    height: 5vh;
    min-width: 125px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 -5px 5px #0000005C;
    z-index: 2;
}

.vl_add_by_date_header_text {

    position: absolute;
    top: 1.2vh;
    left: 2.1vh;

    text-align: center;
    font: normal normal 300 18px/25px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_add_by_date_main {

    position: absolute;
    top: 5vh;
    width: 36vw;
    height: 60vh;
    min-width: 450px;
    min-height: 400px;
    border-radius: 25px;

    /* UI Properties */

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 15px #0000005C;
    z-index: 1;
}

.vl_add_by_date_main_name_edit {

    position: relative;
    top: 2.6vh;
    left: 1.2vw;

    width: 80%;
    height: 4.7vh;
}

.vl_add_by_date_choose_header {

    position: absolute;
    top: 6%;
    left: 1.4vw;

    text-align: center;
    font: normal normal 300 19px/25px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
    opacity: 0.5;
}

.vl_add_by_date_setup_header {

    position: absolute;
    top: 17%;
    left: 1.4vw;

    text-align: center;
    font: normal normal 300 19px/25px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
    opacity: 0.5;
}

.vl_add_by_date_scenes_container {

    position: absolute;
    overflow-y: scroll;
    top: 15%;
    left: 4%;
    width: 92%;
    height: 67%;

    border-top: 2px dotted lightgrey;
    border-bottom: 2px dotted lightgrey;
}

.vl_add_by_date_edit_container {

    position: absolute;
    overflow-y: scroll;
    top: 21.5%;
    left: 4%;
    width: 92%;
    height: 64%;

    padding-top: 20px;

    border-top: 2px dotted lightgrey;
    border-bottom: 2px dotted lightgrey;
}

.vl_add_by_date_scenes_error {

    position: absolute;
    top: 16%;
    left: 50%;
    transform: translate(-50%);
    color: red;
}

.vl_add_by_date_name_error {

    position: absolute;
    top: 7.2vh;
    left: 5%;
    color: red;

    font: normal normal normal 14px/24px Adobe Clean;
}