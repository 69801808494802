.vl_navigation_menu {

    position: absolute;
    right: 0;
    top: 65px;
    width: 267px;
    height: 342px;

    /* UI Properties */

    background: white 0 0 no-repeat padding-box;
    box-shadow: 0 0 20px #00000029;
    border-radius: 30px 0 30px 30px;
}

.vl_navigation_menu_header_container {

    width: 60%;
    height: 25%;
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 9%;

    border-bottom: solid 1px #DADADA;

}

.vl_navigation_menu_header {

    width: 99px;
    height: 42px;
    margin: auto;

    /* UI Properties */

    background: transparent url('/src/ui/images/LogoBlue.svg') 0 0 no-repeat padding-box;
}

.vl_navigation_menu_entry {

    position: relative;
    display: flex;
    flex-direction: row;

    width: 80%;
    height: 15%;
    margin-left: 10%;
    margin-top: 8%;
    padding-top: 15px;

    border-radius: 9px;
}

.vl_navigation_menu_entry:hover {

    background-color: lightgray;
}


.vl_navigation_menu_entry:active {

    background-color: var(--common-active);
}

.vl_navigation_menu_entry p {

    font: normal normal 300 19px/25px Adobe Clean;
}

.vl_navigation_menu_map {

    width: 21px;
    height: 21px;
    margin-left: 12%;
    margin-right: 30px;

    background: transparent url('/src/ui/images/MapIcon.png') 0 0 no-repeat padding-box;
}

.vl_navigation_menu_reports {

    width: 21px;
    height: 21px;
    margin-left: 12%;
    margin-right: 30px;

    background: transparent url('/src/ui/images/ReportsIcon.png') 0 0 no-repeat padding-box;
}

.vl_navigation_menu_monitoring {

    width: 28px;
    height: 21px;
    margin-left: 12%;
    margin-right: 23px;

    background: transparent url('/src/ui/images/MonitoringIcon.png') 0 0 no-repeat padding-box;
}