.vl_intensity_button {

    width: 50px;
    height: 50px;
    border: none;
    border-radius: 25px;
    box-shadow: 0 3px 6px #00000029;
    background-color: white;
}

.vl_intensity_button:active {

    background-color: lightgray;
}

.vl_intensity_button_icon {

    width: 25px;
    height: 25px;
    margin-left: 8px;
    margin-top: 11px;
    background: transparent url('/src/ui/images/Intensity.svg') 0 0 no-repeat padding-box;
}

.vl_intensity_button_icon_chrome {

    width: 25px;
    height: 25px;
    margin-left: 6px;
    margin-top: 11px;
    background: transparent url('/src/ui/images/Intensity.svg') 0 0 no-repeat padding-box;
}