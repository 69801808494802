.vl_profile_edit_panel {

    height: 100%;
}

.vl_profile_edit_password_panel {

    position: relative;
    display: flex;
    flex-direction: row;
}

.vl_profile_edit_password_panel > div {

    width: 50%;
    height: 15%;
    margin-bottom: 4%;
}

.vl_profile_edit_password_panel > div > p {

    position: relative;
    left: 20%;

    font: normal normal normal 20px/30px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_profile_edit_password_panel > div > input {

    position: relative;
    left: 20%;
    height: 50px;
    width: 75%;

    padding-left: 10px;
    border: none;
    border-radius: 9px;
    background-color: white;

    font: normal normal normal 28px/36px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_profile_edit_password_left_5 {

    left: 5% !important;
}

.vl_profile_2fa_href {

    position: absolute;
    left: 10%;
    bottom: 70px;
}

.vl_profile_edit_save {

    position: absolute;
    right: 10%;
    bottom: 60px;
}

.vl_profile_edit_error_message {

    position: absolute;
    top: 100px;
    left: 10%;

    color: red;
    font: normal normal normal 15px/20px Adobe Clean;
}

.vl_profile_edit_loading {

    position: absolute;
    width: 400px;
    height: 200px;
    left: -10%;
    bottom: -20px;
}