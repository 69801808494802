.vl_reports_panel {

    /* Layout Properties */

    position: absolute;
    top: 12.5vh;
    left: 10.25vw;
    width: 79.5vw;
    height: 82.5vh;
    min-width: 1100px;
    min-height: 760px;
    z-index: 1;

    /* UI Properties */

    background-color: var(--common-grey);
    box-shadow: 0 3px 20px #00000029;
    border-radius: 30px;
    opacity: 1;
}

.vl_reports_title {

    margin-left: 7.5%;
    margin-right: auto;

    text-align: left;
    font: normal normal normal 24px/32px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_reports_selectboxes {

    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
}