.vl_activation_scene {

    width: 34vw;
    min-width: 400px;
    background: #EDEDED 0 0 no-repeat padding-box;
    border-radius: 22px;
    margin-bottom: 1.6vh;
    margin-top: 0;
}

.vl_activation_scene_container {

    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10vh;
    min-height: 100px;
}

.vl_activation_scene_edit_button {

    margin-top: 3.5vh;
    width: 26px;
    height: 26px;

    border: none;
    background: transparent url('/src/ui/images/EditFeather.svg') 0 0 no-repeat padding-box;
    background-size: contain;
}

.vl_activation_scene_edit_button:hover {

    background-color: transparent;
}

.vl_activation_scene_edit_button:active {

    background: url('/src/ui/images/EditFeather.svg'), radial-gradient(rgba(0, 0, 0, 0.07), rgba(255, 255, 255, 0.4));
    background-size: contain;
}