.vl_arrow_button {

    width: 50px;
    height: 50px;
    border: none;
    border-radius: 25px;
    background-color: transparent;
}

.vl_arrow_button_icon {

    width: 32px;
    height: 32px;
    margin-left: 16px;
    margin-top: 21px;
    background: transparent url('/src/ui/images/ArrowDown.svg') 0 0 no-repeat padding-box;
}

.vl_arrow_button_icon_reversed {

    width: 32px;
    height: 32px;
    margin-left: 16px;
    margin-top: 21px;
    background: transparent url('/src/ui/images/ArrowUp.svg') 0 0 no-repeat padding-box;
}