.vl_triggerbox_container {

    position: relative;
    width: 68px;
    height: 29px;

    /* UI Properties */

    box-shadow: inset 0 3px 6px #00000029;
    border-radius: 14px;

    -webkit-transition: background-color 350ms linear;
    -ms-transition: background-color 350ms linear;
    transition: background-color 350ms linear;
}

.vl_triggerbox_container.on {

    background: #6dd165 0 0 no-repeat padding-box;
}

.vl_triggerbox_container.off {

    background: #b35930 0 0 no-repeat padding-box;
}

.vl_triggerbox_toggle {

    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 13px;
    z-index: 10;

    background: radial-gradient(ellipse at bottom, #ddd, white);
    box-shadow: 0 1px 2px #999;
}

.vl_triggerbox_toggle.on {

    top: 2px;
    right: 2px;

    animation:vl_move_to_right_toggle 350ms ease-in;
    -moz-animation:vl_move_to_right_toggle 350ms ease-in; /* Firefox */
    -webkit-animation:vl_move_to_right_toggle 350ms ease-in; /* Safari and Chrome */
}

.vl_triggerbox_toggle.off {

    top: 2px;
    left: 2px;

    animation:vl_move_to_left_toggle 350ms ease-in;
    -moz-animation:vl_move_to_left_toggle 350ms ease-in; /* Firefox */
    -webkit-animation:vl_move_to_left_toggle 350ms ease-in; /* Safari and Chrome */
}

@keyframes vl_move_to_right_toggle {
    from {left: 2px;}
    to {left: 42px;}
}

@-moz-keyframes vl_move_to_right_toggle {
    from {left: 2px;}
    to {left: 42px;}
}

@-webkit-keyframes vl_move_to_right_toggle {
    from {left: 2px;}
    to {left: 42px;}
}

@keyframes vl_move_to_left_toggle {
    from {left: 40px;}
    to {left: 2px;}
}

@-moz-keyframes vl_move_to_left_toggle {
    from {left: 40px;}
    to {left: 2px;}
}

@-webkit-keyframes vl_move_to_left_toggle {
    from {left: 40px;}
    to {left: 2px;}
}

.vl_triggerbox_text {

    position: absolute;

    color: rgba(0, 0, 0, 0.7);
    font: normal normal normal 14px/23px Adobe Clean;
    font-weight: bold;
    letter-spacing: 0;
    user-select: none;
}

.vl_triggerbox_text.on {

    top: 4px;
    left: 10px;

    animation:vl_triggerbox_text_on_appear 1s ease-out;
    -moz-animation:vl_triggerbox_text_on_appear 1s ease-out; /* Firefox */
    -webkit-animation:vl_triggerbox_text_on_appear 1s ease-out; /* Safari and Chrome */
}

.vl_triggerbox_text.off {

    top: 4px;
    right: 6px;

    animation: vl_triggerbox_text_off_appear 1s ease-out;
    -moz-animation: vl_triggerbox_text_off_appear 1s ease-out; /* Firefox */
    -webkit-animation: vl_triggerbox_text_off_appear 1s ease-out; /* Safari and Chrome */
}

@keyframes vl_triggerbox_text_on_appear {
    from {color: rgba(0, 0, 0, 0);}
    to {color: rgba(0, 0, 0, 0.7);}
}

@-moz-keyframes vl_triggerbox_text_on_appear {
    from {color: rgba(0, 0, 0, 0);}
    to {color: rgba(0, 0, 0, 0.7);}
}

@-webkit-keyframes vl_triggerbox_text_on_appear {
    from {color: rgba(0, 0, 0, 0);}
    to {color: rgba(0, 0, 0, 0.7);}
}

@keyframes vl_triggerbox_text_off_appear {
    from {color: rgba(0, 0, 0, 0);}
    to {color: rgba(0, 0, 0, 0.7);}
}

@-moz-keyframes vl_triggerbox_text_off_appear {
    from {color: rgba(0, 0, 0, 0);}
    to {color: rgba(0, 0, 0, 0.7);}
}

@-webkit-keyframes vl_triggerbox_text_off_appear {
    from {color: rgba(0, 0, 0, 0);}
    to {color: rgba(0, 0, 0, 0.7);}
}