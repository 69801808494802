.vl_time_edit_container {

    position: relative;
    display: flex;
    flex-direction: row;

    width: 120px;
    background-color: green;
}

.vl_time_edit_hour {

    /* UI Properties */

    border: none;

    width: 50%;
    height: 100%;
    /*background-color: green;*/

    text-align: center;
    font: normal normal 300 30px/30px Adobe Clean;
}

.vl_time_edit_minute {

    border: none;

    width: 50%;
    height: 100%;
    /*background-color: yellow;*/

    text-align: center;
    font: normal normal 300 30px/30px Adobe Clean;
}

.vl_time_edit_divider {

    position: absolute;
    width: 10px;
    height: 40px;

    top: -5px;

    left: 50%;
    transform: translate(-50%);

    text-align: center;
    font: normal normal 300 35px/40px Adobe Clean;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

}