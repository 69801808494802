.vl_users_table_container {
    margin-top: 10%;
    width: 86%;
    height: 68%;

    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
}

.vl_users_table {
    width: 95%;

    font: normal normal 300 18px/25px Adobe Clean;
}

.vl_users_table th, td {
    width: 25%;
    overflow: hidden;
}

.vl_users_table_header {

    height: 60px;
    text-align: center;
    vertical-align: top;
    font-size: 1.2rem;

}

.vl_users_table_row {

    border-top: 1px solid lightgrey;
    height: 60px;
    text-align: center;
    font-size: 1.2rem;
}

.vl_users_table_row .status {

    margin: auto;
    padding: 4px;
    width: 60%;
    min-width: 80px;
    min-height: 29px;

    text-align: center;
    vertical-align: center;
    font: normal normal normal 18px/22px Adobe Clean;
    letter-spacing: 0;
    color: #FFFFFF;
    text-transform: uppercase;
}

.vl_users_table_status_admin {

    background: transparent linear-gradient(180deg, #51B2D6 0%, #347187 73%, #29596B 100%) 0 0 no-repeat padding-box;
    border-radius: 8px;
}

.vl_users_table_status_user {

    background: transparent linear-gradient(180deg, #C6C6C6 0%, #636363 100%) 0 0 no-repeat padding-box;
    border-radius: 8px;
}

.vl_users_table_delete_icon {

    width: 32px;
    height: 32px;
    margin-left: 5px;
    margin-top: 10px;
    background: transparent url('/src/ui/images/Delete.svg') 0 0 no-repeat padding-box;
    background-size: cover;
    float: left;
}