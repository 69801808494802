.vl_undelete_button {

    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
}

.vl_undelete_button:active {

    background-color: lightgray;
}

.vl_undelete_button_icon {

    width: 32px;
    height: 32px;
    background: transparent url('/src/ui/images/Undelete.png') 0 0 no-repeat padding-box;
    background-size: cover;
}

.vl_undelete_button_icon_chrome {

    width: 32px;
    height: 32px;
    background: transparent url('/src/ui/images/Undelete.png') 0 0 no-repeat padding-box;
    background-size: cover;
}