.vl_map_container {

    margin-top: 2vh;
    width: 55.5%;
    height: 100%;
    padding-right: 88px;
}

.vl_map_leaflet {

    flex: 1 1 auto;
    padding: 1rem 1rem;
    height: 65%;
    border: 2px solid black;
}

.vl_map_marker, .vl_map_marker_off, .vl_map_marker_sensor {

    position: relative;
    width: 32px;
    height: 32px;
    border: none;
}

.vl_map_marker_light {

    background: url('/src/ui/images/Light.png');
    background-size: cover;
}

.vl_map_marker_occupancy {

    background: url('/src/ui/images/Occupancy.png');
    background-size: cover;
}

.vl_map_marker_button {

    background: url('/src/ui/images/Button.png');
    background-size: cover;
}

.vl_map_marker_unknown {

    background: url('/src/ui/images/Unknown.png');
    background-size: cover;
}

.vl_map_marker_active {

    position: relative;
    width: 32px;
    height: 32px;
    border: none;
}

.vl_map_marker_active_light {

    background: url('/src/ui/images/ActiveLight.png');
    background-size: cover;
}

.vl_map_marker_active_occupancy {

    background: url('/src/ui/images/ActiveOccupancy.png');
    background-size: cover;
}

.vl_map_marker_active_button {

    background: url('/src/ui/images/ActiveButton.png');
    background-size: cover;
}

.vl_map_marker_active_unknown {

    background: url('/src/ui/images/ActiveUnknown.png');
    background-size: cover;
}

.vl_map_marker p {

    position: absolute;
    text-align: center;
    top: 32px;
    left: -29px;
    width: 90px;

    color: royalblue;
    font: normal normal 300 13px/20px Adobe Clean;
}

.vl_map_marker_off p {

    position: absolute;
    text-align: center;
    top: 32px;
    left: -29px;
    width: 90px;

    color: lightcoral;
    font: normal normal 300 13px/20px Adobe Clean;
}

.vl_map_marker_sensor p {

    position: absolute;
    text-align: center;
    top: 32px;
    left: -29px;
    width: 90px;

    color: darkviolet;
    font: normal normal 300 13px/20px Adobe Clean;
}

.vl_map_marker_active p {

    position: absolute;
    text-align: center;
    top: 32px;
    left: -29px;
    width: 90px;

    color: #008000;
    font: normal normal 300 13px/20px Adobe Clean;
    font-weight: bold;

}

.vl_map_loading_container {

    position: absolute;

    width: 100%;
    height: 100%;
    z-index: 9999;
}