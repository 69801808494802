.vl_search_container {

    position: relative;
    margin-left: auto;
}

.vl_search_input {

    background-color: var(--common-grey);
    border-width: 2px;
    border-style: none;
    border-bottom-style: solid;
    border-color: lightgrey;
    margin-right: 20px;
}

.vl_search_icon {

    position: absolute;
    left: 163px;
    bottom: 18px;
    width: 20px;
    height: 20px;
    background: transparent url('/src/ui/images/Search.svg') 0 0 no-repeat padding-box;
}

.vl_placeholder {

    position: absolute;
    left: 188px;
    bottom: 16px;
    background-color: var(--common-grey);
    color: grey;
}

.vl_search_input:focus {

    background-color: var(--common-grey);
    border-width: 2px;
    border-style: none;
    border-bottom-style: solid;
    border-color: lightgrey;
    color: black;
}

input:focus {
    outline: none !important;
}