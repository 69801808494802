.vl_banner {
}

.vl_banner_icon {

    /* Layout Properties */

    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;

    /* UI Properties */

    background: transparent url('/src/ui/images/Logo.svg') 0 0 no-repeat padding-box;
    background-size: cover;
    opacity: 1;
}

.vl_banner_icon_size_small {

    width: 150px;
    height: 64px;
}

.vl_banner_icon_size_big {

    width: 250px;
    height: 110px;
}

.vl_banner_title {

    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;

    /* UI Properties */

    background: transparent url('/src/ui/images/VISIBLE_CONNECT.svg') 64% 50% no-repeat;
    background-size: 200% 144%;
}

.vl_banner_title_size_small {

    width: 270px;
    height: 20px;
}

.vl_banner_title_size_big {

    width: 500px;
    height: 38px;
}