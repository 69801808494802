.vl_power_button {

    border: none;
    padding: 0;
}

.vl_power_button:hover {

    background-color: transparent;
}

.vl_power_button:active {

    border-radius: 30px;
    background: radial-gradient(rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0.5));
}

.vl_power_button_icon {

    width: 100%;
    height: 100%;
    margin: 0;
    background: transparent url('/src/ui/images/RedTrigger.svg') 0 0 no-repeat padding-box;
    background-size: 102% 108%;
}
