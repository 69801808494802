.vl_parameters_list {

    background-color: white;
    border-radius: 26px;
    padding: 10px 20px 20px;
}

.vl_parameters_list_parameter_container {

    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin: 5px;
}

.vl_parameters_list_parameter_name {

    font: normal normal normal 20px/32px Adobe Clean;
    margin-top: 10px;
    margin-left: 10px;
}