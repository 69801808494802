.sidebar_left {
    width: 14%;
    height: 100%;
    position: fixed;
    top: 0;
    background-image: linear-gradient(180deg, rgb(5, 39, 103) 0%, #3a0647 70%);
}

.sidebar_right {
    width: 14%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-image: linear-gradient(180deg, rgb(5, 39, 103) 0%, #3a0647 70%);
}