.vl_all_data_tab {

    height: 70%;
}

.vl_all_data_info_bar {

    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
    margin-top: 2%;
}

.vl_all_data_title {

    margin-left: 7.5%;
    margin-right: auto;
    width: 35%;

    text-align: left;
    font: normal normal normal 21px/32px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_all_data_chart_container {

    display: flex;
    flex-direction: row;

    margin-left: 7.5%;
    margin-right: 7.5%;
    margin-top: 20px;
    height: 90%;
    max-height: 700px;
}

.vl_all_data_info_container {

    width: 28%;
    height: 90%;

    margin-left: 2%;
}

.vl_all_data_info_power {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 47.5%;

    margin-bottom: 6.5%;
    border-radius: 30px;

    background-color: white;
}

.vl_all_data_info_power_icon {

    width: 80px;
    height: 70px;

    margin-bottom: 20px;

    background: transparent url('/src/ui/images/Energy.svg') 0 0 no-repeat padding-box;
    background-size: contain;
}

.vl_all_data_info_on_hours {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 47.5%;

    border-radius: 30px;

    background-color: white;
}

.vl_all_data_info_on_hours_icon {

    width: 60px;
    height: 70px;

    margin-bottom: 20px;

    background: transparent url('/src/ui/images/OnHours.svg') 0 0 no-repeat padding-box;
    background-size: contain;
}

.vl_all_data_card_title {

    font: normal normal bold 20px/28px Adobe Clean;
    letter-spacing: 0;
}

.vl_all_data_card_value {

    font: normal normal normal 20px/28px Adobe Clean;
    letter-spacing: 0;
}