.vl_user_dialog {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 700px;
    height: 350px;

    /* UI Properties */

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 20px #00000029;
    border-radius: 30px;

    z-index: 10000;
}

.vl_user_text {

    position: absolute;
    top: 27px;
    left: 30px;

    font: normal normal normal 20px/32px Adobe Clean;
}

.vl_user_dialog_nickname {

    position: absolute;
    width: 270px;
    top: 30%;
    left: 8%;

    padding: 4px;
    border-radius: 5px;
    border: 1px solid lightgrey;

    font: normal normal normal 20px/32px Adobe Clean;
}

.vl_user_dialog_password {

    position: absolute;
    width: 270px;
    top: 30%;
    left: 52%;

    padding: 4px;
    border-radius: 5px;
    border: 1px solid lightgrey;

    font: normal normal normal 20px/32px Adobe Clean;
}

.vl_user_dialog_status {

    position: absolute;
    top: 55%;
    left: 10%;
}

.vl_user_dialog_status_user {

    position: relative;
    bottom: 2px;
}

.vl_user_dialog_status_user_text {

    margin-left: 6px;
    font: normal normal normal 20px/25px Adobe Clean;
}

.vl_user_dialog_status_admin {

    position: relative;
    bottom: 2px;
    margin-left: 25px;
}

.vl_user_dialog_status_admin_text {

    margin-left: 6px;
    font: normal normal normal 20px/25px Adobe Clean;
}

.vl_user_dialog_save {

    position: absolute;

    bottom: 8%;
    left: 50%;
    transform: translate(15%, 0);
}

.vl_user_dialog_cancel {

    position: absolute;

    bottom: 8%;
    left: 50%;
    transform: translate(-115%, 0);
}

.vl_user_dialog_error {

    position: absolute;

    top: 60px;
    left: 30px;

    font: normal normal normal 15px/20px Adobe Clean;
    color: lightcoral;
}