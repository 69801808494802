.vl_add_scene {

    position: relative;
}

.vl_add_scene_header {

    position: absolute;
    top: 2vh;
    right: 2.2vw;
    width: 8vw;
    height: 5vh;
    min-width: 125px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 -5px 5px #0000005C;
    z-index: 2;
}

.vl_add_scene_header_text {

    position: absolute;
    top: 1.2vh;
    left: 2.1vh;

    text-align: center;
    font: normal normal 300 19px/25px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_add_scene_main {

    position: absolute;
    top: 7vh;
    width: 36vw;
    height: 66vh;
    min-width: 450px;
    min-height: 400px;
    border-radius: 25px;

    /* UI Properties */

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 15px #0000005C;
    z-index: 1;
}

.vl_add_scene_main_name_edit {

    position: relative;
    top: 2.6vh;
    left: 1.2vw;

    width: 80%;
    height: 4.7vh;
}

.vl_add_scene_choose_header {

    position: absolute;
    top: 16%;
    left: 1.4vw;

    text-align: center;
    font: normal normal 300 19px/25px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
    opacity: 0.5;
}

.vl_add_scene_areas_container {

    position: absolute;
    overflow-y: scroll;
    top: 22%;
    left: 4%;
    width: 92%;
    height: 62.5%;
    border-top: 2px dotted lightgrey;
    border-bottom: 2px dotted lightgrey;
}

.vl_add_scene_show_realtime_checkbox {

    position: absolute;
    bottom: 3.9vh;
    left: 35px;
}

.vl_add_scene_show_realtime_text {

    position: absolute;
    bottom: 2.2vh;
    left: 70px;

    font: normal normal normal 15px/25px Adobe Clean;
}

.vl_add_scene_device_error {

    position: relative;
    top: 1vh;
    left: 10px;

    font: normal normal 300 15px/20px Adobe Clean;
    color: red;
}

.vl_add_scene_name_error {

    position: absolute;
    top: 7.5vh;
    left: 36px;

    font: normal normal 300 15px/20px Adobe Clean;
    color: red;
}