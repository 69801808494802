.vl_checkbox_container {

    position: relative;
    width: 25px;
    height: 25px;
    padding: 0;
}

.vl_checkbox_button {

    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
}

.vl_checkbox_button_active {

    background: transparent url('/src/ui/images/CheckFilled.svg') 0 0 no-repeat padding-box;
}

.vl_checkbox_button_inactive {

    background: transparent url('/src/ui/images/CheckEmpty.svg') 0 0 no-repeat padding-box;
}