.vl_profile_panel {

    /* Layout Properties */

    position: absolute;
    top: 12.6vh;
    left: 32.25vw;
    width: 35.5vw;
    height: 82.13vh;
    min-width: 600px;
    min-height: 700px;
    z-index: 1;

    /* UI Properties */

    background-color: var(--common-grey);
    box-shadow: 0 3px 20px #00000029;
    border-radius: 30px;
    opacity: 1;
}

.vl_profile_panel:disabled {

    background-color: var(--common-grey);
}

.vl_profile_header {

    margin-bottom: 7%;
    font: normal normal normal 42px/59px Adobe Clean !important;
}

.vl_profile_entry {

    position: relative;
    width: 100%;
    height: 13%;
    margin-bottom: 6%;
}

.vl_profile_title {

    position: relative;
    left: 10%;

    font: normal normal normal 20px/30px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_profile_data {

    position: relative;
    left: 10%;
    border: none;
    background-color: var(--common-grey);

    font: normal normal normal 34px/44px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_profile_edit {

    position: relative;
    left: 10%;
    width: 80%;
    height: 50px;

    padding-left: 10px;
    border: none;
    border-radius: 9px;
    background-color: white;

    font: normal normal normal 30px/44px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_profile_href {

    border: none;
    text-decoration: underline;

    background-color: var(--common-grey);
    font: normal normal normal 17px/25px Adobe Clean;
    color: #51B2D6;
}

.vl_profile_href:active {

    color: lightcoral;
}