.vl_activation_scene_by_date_edit_container_long {

}

.vl_activation_scene_by_date_edit_container {

}

.vl_activation_scene_by_date_scene {

    width: 80%;
    height: 50px;

    margin-left: 10%;
    margin-top: 10px;

    border-radius: 20px;
    background-color: #a5a5a5;
}

.vl_activation_scene_by_date_scene p {

    height: 100%;
    padding: 11px 0;

    text-align: center;

    font: normal normal normal 19px/26px Adobe Clean;
    color: white;
}

