.vl_login_panel {

    /* Layout Properties */

    position: absolute;
    top: 13.3%;
    left: 28.5%;
    width: 43%;
    height: 73.4%;
    min-width: 500px;
    min-height: 600px;

    /* UI Properties */

    background: rgba(0, 0, 0, 0.5) 0 0 no-repeat padding-box;
    border-radius: 23px;
}

.vl_login_input {

    background: transparent;
    color: white;
    width: 64%;
    border-style: none;
    border-width: 1px;
    border-bottom-style: solid;
    border-color: white;
    font-size: 1.6rem;

    margin-left: 18%;
    margin-top: 9%;
    padding-bottom: 1.4rem;
}

.vl_login_error {

    position: absolute;
    color: red;
    bottom: 12.5%;
    left: 18%;

    font: normal normal normal 20px/25px Adobe Clean;
}

.vl_login_loading_container {

    position: absolute;
    bottom: 12.5%;
    left: 18%;

    width: 20%;
    height: 5%;
}