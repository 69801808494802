.vl_profile_info_panel {

}

.vl_profile_info_panel_content {

}

.vl_profile_delete_href {

    position: absolute;
    left: 10%;
    bottom: 70px;
}