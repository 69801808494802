.vl_map_view_container {

    position: relative;
    width: 100%;
    height: 100%;
}

.vl_map_view_map_container {

    position: absolute;
    top: 8%;
    left: 8%;

    flex: 1 1 auto;
    padding: 1rem 1rem;
    height: 92%;
    width: 92%;
}

.vl_map_view_horizontal_slider {

    position: absolute !important;
    left: 8%;

    width: 92%;
}

.vl_map_view_vertical_slider {

    position: absolute !important;
    top: 8%;

    height: 92%;
}