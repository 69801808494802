.vl_main_panel {

    /* Layout Properties */

    position: absolute;
    top: 74px;
    left: 2vw;
    width: 96vw;
    height: 90vh;
    min-width: 1220px;
    min-height: 780px;

    /* UI Properties */

    background: var(--background-panel);
    box-shadow: 0 3px 20px #00000029;
    border-radius: 30px;
    opacity: 1;

    display: flex;
    flex-direction: row;
}

.vl_left_panel {

    /* Flex */

    display: flex;
    flex-direction: column;
    height: 100%;
    width: 44.5%;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}