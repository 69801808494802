.vl_sensor_events_tab {

    height: 70%;
}

.vl_sensor_events_info_bar {

    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
    margin-top: 2%;
}


.vl_sensor_events_title {

    margin-left: 7.5%;
    margin-right: auto;
    width: 50%;

    text-align: left;
    font: normal normal normal 19px/32px Adobe Clean;
    letter-spacing: 0;
    color: #000000;
}

.vl_sensor_events_chart_container {

    margin-left: 7.5%;
    margin-right: 7.5%;
    margin-top: 20px;
    height: 83%;
}