.vl_name_edit_container {

    position: relative;
    display: flex;
    flex-direction: row;
}

.vl_name_edit_input {

    background-color: transparent;
    /* UI Properties */

    border: none;

    width: 100%;
    height: 100%;

    font: normal normal 300 17px/25px Adobe Clean;
}

.vl_name_edit_accept {

    position: relative;
    height: 25px;
    width: 40px;
    top: 15px;
    left: 9px;
    background: transparent url('/src/ui/images/Available.png') 0 0 no-repeat padding-box;
    cursor: pointer;
}

.vl_name_edit_cancel {

    position: relative;
    height: 35px;
    width: 50px;
    top: 9px;
    left: 0;
    background: transparent url('/src/ui/images/Error.svg') 0 0 no-repeat padding-box;
    cursor: pointer;
}

.vl_name_edit_error {

    position: absolute;
    bottom: -12px;
    color: red;

    font: normal normal 300 15px/25px Adobe Clean;
}