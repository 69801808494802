.vl_devices_tab {
    position: relative;
    margin-top: 1vh;
    height: 74%;
    overflow-y: scroll;
}

.vl_devices_tab_without_scroll {

    overflow-y: hidden !important;
}

.vl_devices_tab_add_group {

    position: relative;
    height: 100%;
    padding-left: 12px;
    overflow-y: hidden;
}