.vl_profile_2fa_panel {

}

.vl_profile_2fa_link {

    color: royalblue;
    text-decoration: none;

    cursor: pointer;
}

.vl_profile_2fa_link:active {

    color: red;
}


.vl_profile_2fa_text {

    width: 90%;

    margin-left: 10%;
    margin-bottom: 5%;
    font: normal normal normal 18px/32px Adobe Clean;
}

.vl_profile_2fa_qr {

    display: flex;
    justify-content: center;

    width: 100%;
    padding-bottom: 5%;
}

.vl_profile_2fa_cancel_button {

    position: absolute;
    bottom: 4.5vh;
    right: 210px;
}

.vl_profile_2fa_check_button {

    position: absolute;
    bottom: 4.5vh;
    right: 30px;
}