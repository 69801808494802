.vl_charts_list {

    position: relative;

    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;

    overflow-y: scroll;
}

.vl_charts_chart_container {

    position: relative;

    width: 90%;
    height: 50vh;
    margin-left: 5%;
    margin-top: 40px;
    margin-bottom: 80px;
}

.vl_charts_chart_info_container {

    display: flex;
    flex-direction: row;

    width: 100%;
}

.vl_charts_chart_info_device {

    width: 50%;
    text-align: left;

    padding-top: 5px;
    margin-left: 20px;
    font: normal normal normal 15px/25px Adobe Clean;
}

.vl_charts_chart_info_metadata {

    width: 50%;
    text-align: right;

    padding-top: 5px;
    margin-right: 20px;
    font: normal normal normal 17px/28px Adobe Clean;
}

.vl_charts_chart_info_total {

    position: relative;
    top: 5px;

    width: 50%;
    margin-right: 20px;

    color: royalblue;

    font: normal normal normal 17px/28px Adobe Clean;
    text-align: right;
}