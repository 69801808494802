.vl_notifications_menu {

    position: absolute;
    right: 10%;
    top: 65px;
    width: 267px;
    height: 342px;

    /* UI Properties */

    background: white 0 0 no-repeat padding-box;
    box-shadow: 0 0 20px #00000029;
    border-radius: 30px 0 30px 30px;
}

.vl_notifications_menu_container {

    height: 90%;
    overflow-y: scroll;
}

.vl_notifications_menu_header_container {

    width: 60%;
    height: 25%;
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 9%;

    border-bottom: solid 1px #DADADA;

}

.vl_notifications_menu_info {

    width: 100%;
    height: 10%;
    margin-top: 25px;

    text-align: center;
    font: normal normal 300 18px/25px Adobe Clean;
}