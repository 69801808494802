.vl_device_container {

    display: flex;
    flex-direction: row;
}

.vl_device {

    display: flex;
    flex-direction: row;
    position: relative;
    width: 544px;
    height: 66px;
    background-color: #e8e8e8;
    border-radius: 29px;
    margin-bottom: 10px;
}

.vl_device_opened {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    width: 544px;
    height: 350px;
    background-color: #e8e8e8;
    border-radius: 29px;
    margin-bottom: 10px;
}

.vl_device_active {

    background-color: #FFF8A3 !important;
}

.vl_device_error {

    background-color: #ffb2b2 !important;
}

.vl_device_status_ok {

    position: relative;
    height: 25px;
    width: 70px;
    top: 20px;
    left: 25px;
    background: transparent url('/src/ui/images/Available.png') 0 0 no-repeat padding-box;
}

.vl_device_status_error {

    font-size: 0.7rem;
    height: 66px;
    width: 70px;
    text-align: center;
    vertical-align: center;
    margin: 0;
    display: table-cell;
    line-height: 16px;
    padding-top: 14px;
    padding-left: 10px;
    color: red;
    text-decoration: underline;
}

.vl_device_info_container {

    padding: 10px 10px 10px 20px;
}

.vl_device_check_box_container {

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 12px;
    margin-right: 14px;
}

.vl_device_name {

    margin-bottom: 0;
    cursor: pointer;
}

.vl_device_name_edit {

    width: 100%;
    height: 32px;
    margin-top: -8px;
    margin-bottom: 5px;
}

.vl_device_name_edit_input {

    margin-top: 11px;
    width: 80% !important;
    height: 50% !important;
    border-bottom: 1px solid grey !important;
    font: normal normal 300 14px/25px Adobe Clean !important;
}

.vl_device_name_edit_error {

    bottom: -30px !important;
    font: normal normal 300 13px/25px Adobe Clean !important;
}

.vl_device_info {

    font-size: 0.8rem;
    margin-right: 1.2rem;
}

.vl_device_info_table_container {

    display: flex;
    flex-direction: row;

    width: 100%;
    height: 235px;
    overflow-y: scroll;
}

.vl_device_info_table {

    width: 90%;
    margin-left: 5%;
    margin-bottom: 15px;
    color: #212529;
    vertical-align: top;

    font: normal normal 300 13px/25px Adobe Clean;
}

.vl_device_info_table_row {

    border-bottom: 2px dotted #777777;
}

.vl_device_info_table_general_entry {

    font: normal normal 300 16px/25px Adobe Clean !important;
    font-weight: bold !important;
}

.vl_device_info_table_item_title {

    padding: 15px;
    text-align: right;
    border-right: 2px dotted #777777;

    width: 44%;
}

.vl_device_info_table_item_value {

    padding: 15px;
    text-align: left;

    width: 56%;
}

.vl_device_buttons {

    display: flex;
    flex-direction: row;
    padding: 8px;
    margin-left: auto;
    margin-right: 5px;
}

.vl_device_loading_container {

    position: absolute;
    top: 5%;
    right: 25%;

    width: 20%;
    height: 5%;
}