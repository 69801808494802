.vl_error_dialog {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 450px;
    height: 260px;

    /* UI Properties */

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 20px #00000029;
    border-radius: 30px;

    z-index: 10000;
}

.vl_error_dialog_icon {

    position: absolute;
    top: 7%;
    left: 50%;
    transform: translate(-50%);

    width: 60px;
    height: 60px;

    background: transparent url('/src/ui/images/Error.svg') 0 0 no-repeat padding-box;
    background-size: cover;
}

.vl_error_dialog_text {

    position: absolute;
    top: 34%;
    left: 5%;
    width: 90%;
    text-align: center;

    font: normal normal 300 18px/25px Adobe Clean;
}

.vl_error_dialog_ok {

    position: absolute;

    bottom: 10%;
    left: 50%;
    transform: translate(-50%);
}